<template>
    <div class="panel">
        <div>
            <a-icon type='warning'></a-icon>
            <p>{{message}}</p>
        </div>
    </div>
</template>
<script>
    import { getLocalStore } from '@/utils/storage';
    export default {
        data () {
            return {
                message: '请在微信客户端访问'
            }
        },
        created() {
            if (this.$route.query.errorInfo) {
                this.message = getLocalStore('accessInfo')
            }
        }
    }
</script>
<style lang="less" scope>
    .panel {
        height: 100%;
        background: #f6f6f6;
        text-align: center;
        padding-top: 80px;
        color: red;
        font-size: 19.2px;
        div {
                padding: 48px;
                background: white;
                margin: 16px;
        }
        .anticon {
                font-size: 48px;
                margin-bottom: 16px;
        }

    }
</style>